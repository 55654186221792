/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #131d30;
  text-decoration: none !important;
}

a:hover {
  color: #131d30;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

.container_format {
  /*background-color:#f3d8a3eb; #D6DEE0 ;*/
  background-color: white;
  border-bottom: 0.5rem solid  #D6DEE0;
}

.container_text{
  color: #131d30;
  font-weight: 900;
  letter-spacing: 2;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:  0;
  margin: 0;
  background-color: #131d30;
  color: #fff;
  font-family: "Poppins", sans-serif;
 }

 .navbar-nav {
    --bs-navbar-color: white;
 }

 a.logo{
  font-weight:bold;
  font-size: 30px; 
  font-weight:900; 
  color: white;
}
 .nav-links a {
  color: #fff;
 }

 /* NAVBAR MENU */
.nav-links {
  display: flex;
  font-size: 14px;
  margin: 0;
  padding: 0;
 }
 
 .nav-links li:hover {
  border-radius: 50px;
  background-color:#db3a34;
  
 }

 .nav-button{
  display: flex;
  font-size: 14px;
  margin: 0;
  padding: 0;
  border-radius: 50px;
  background-color:#db3a34;
 }

 .navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  margin-left: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-toggler {
  margin-right: 16px;
}

@media (max-width: 992px) {
  .navbar-nav {
    margin-left: 30px;
  }
}

.resource-button {
  display: inline-block;
    background: #db3a34;
    padding: 0px 10px 0px 10px !important;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
    margin-right: 16px;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #37423b;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}


/*--------------------------------------------------------------
# Home Page Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background: url("../public/images/homepage.png") top center;
  background-size: cover;
  position: relative;
}
#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .container {
  padding-top: 72px;
}
@media (max-width: 992px) {
  #hero .container {
    padding-top: 62px;
  }
}
#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
#hero h2 {
  color: #eee;
  margin: 10px 0 0 0;
  font-size: 24px;
}
#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 30px;
  background: #f0b53ee6;
  border: 1px solid #f0b53ee6;
  color: #fff;
}
#hero .btn-get-started:hover {
  border: 1px solid white;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #131d30;
}

.section-title {
  padding-bottom: 40px;
}
.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #9ae1af;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #37423b;
}

.breadcrumbs {
  margin-top: 73px;
  text-align: center;
  background: #20a8c0;
  padding: 30px 0;
  color: #fff;
}
@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 63px;
  }
}
.breadcrumbs h2 {
  font-size: 32px;
  font-weight: 500;
}
.breadcrumbs p {
  font-size: 14px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 30px;
  background: #131d30;
  border-radius: 4px;
  color: #fff;
}
.why-us .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}
.why-us .content p {
  margin-bottom: 30px;
}
.why-us .content .more-btn {
  display: inline-block;
  background: #db3a34;
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}
.why-us .content .more-btn i {
  font-size: 14px;
}
.why-us .content .more-btn:hover {
  color: white;
  background: #db3a34;
  border: 1px solid white;
}
.why-us .icon-boxes .icon-box {
  text-align: center;
  background: #fff;
  padding: 40px 30px;
  width: 100%;
  border: 1px solid #D6DEE0;
}
.why-us .icon-boxes .icon-box i {
  font-size: 32px;
  padding: 18px;
  color: #20a8c0;
  margin-bottom: 30px;
  background: #ecf9f0;
  border-radius: 50px;
}
.why-us .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px 0;
}
.why-us .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 30px 0;
}
.counts .counters span {
  font-size: 48px;
  display: block;
  color: white;
  font-weight: 700;
}
.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: white;
}

/*--------------------------------------------------------------
# Courses
--------------------------------------------------------------*/
.courses {
  padding: 16px 0px !important;
}

.courses .course-item {
  border-radius: 5px;
  border: 1px solid #eef0ef;
  background: white;
}
.courses .course-content {
  padding: 15px;
}
.courses .course-content h3 {
  font-weight: 700;
  font-size: 20px;
}
.courses .course-content h3 a {
  color: #37423b;
  transition: 0.3s;
}
.courses .course-content h3 a:hover {
  color: #131d30;
}
.courses .course-content p {
  font-size: 14px;
  color: #777777;
}
.courses .course-content h4 {
  font-size: 14px;
  background: #131d30;
  padding: 7px 14px;
  color: #fff;
  margin: 0;
}

.site-footer
{
  background-color:#f3d8a3eb;
  border-top: 0.5rem solid  #b2b5b691;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:black;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#131d30;
  font-size:16px;
  font-weight: bold;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:1px
}
.site-footer a
{
  color:#131d30;
}
.site-footer a:hover
{
  color:#db3a34;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#131d30
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#db3a34;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
.container-fluid-custom {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

#map {
  position: relative;
  left: 0px;
  width: 100%;
  height: 600px;
}

.marker {
  width: 25px;
  height: 25px;
  background-size: 100%;
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.map-container {
  position: relative;
  left: 0px;
  width: 100%;
  height: 600px;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.marker {
  width: 25px;
  height: 25px;
  background-size: 100%;
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.filter-dropdown select {
  appearance: none;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.filter-dropdown select:hover {
  background-color: #e0e0e0;
}

.filter-dropdown select:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

/* Optionally style the options */
.filter-dropdown select option {
  background-color: #fff;
  color: #333;
}

.courses .more-btn {
  display: inline-block;
  background: #db3a34;
  padding: 7px 14px;
  color: #fff;
}
.courses .more-btn i {
  font-size: 14px;
}
.courses .more-btn:hover {
  color: white;
  background: #db3a34;
  border: 1px solid black;
}

.category-label {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #131d30;
  color: white;
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
  font-weight: bold;
  z-index: 1;
}
